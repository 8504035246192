<template>
  <div class="film-home position-relative">
    <b-overlay :show="filmBusy">
      <pagination :meta="filmsMeta" place-holder="فىلىم نامىنى كىرگۈزۈپ ئىزدەڭ" @change-page="onChangePage" @refresh="onRefresh">
        <b-button slot="action" :to="{name: 'film.store'}" class="btn btn-label-success btn-sm border-0">
          يېڭىدىن قوشۇش
        </b-button>
      </pagination>
      <b-row class="mb-3" v-if="films.length">
        <b-col v-if="false">
          <b-btn-group>
            <b-button variant="primary" aria-controls="film_ids" @click="onClickToggleSelect"> {{ allSelected ? 'بىكار قىلىش' : 'ھەممىسىنى تاللاش'}} </b-button>
            <b-dropdown v-if="film_ids.length" variant="warning" right no-caret>
              <template v-slot:button-content>
                <i class="fa fa-angle-down"></i>
              </template>
              <b-dropdown-item>ئۆچۈرۈش</b-dropdown-item>
            </b-dropdown>
          </b-btn-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col v-for="film in films" :key="`region_${film.id}`" md="3" sm="6">
          <portlet :title="` `" body-fluid body-class="px-3 pb-3 pt-0" headNoBorder head-class="px-2" head-size="sm">
            <template slot="toolbar">
              <a v-if="film.sort" @click="onClickCancelPushUp(film.id)" v-b-tooltip title="ئۈستىگە ئىتتىرىلگەن فىلىم، بېسىش ئارقىلىق بىكار قىلىڭ" class="text-decoration-none border-0 btn btn-clean btn-sm btn-icon btn-icon-md">
                <i class="fa fa-sort-amount-up"></i>
              </a>
<!--              <b-checkbox name="film_ids" :value="film.id" v-model="film_ids" />-->
              <b-dropdown v-b-tooltip title="مەشغۇلات" toggle-class="text-decoration-none border-0 btn btn-clean btn-sm btn-icon btn-icon-md" no-caret>
                <template v-slot:button-content>
                  <i class="fa fa-ellipsis-v"></i>
                </template>
                <b-dropdown-text >
                  <b-checkbox switch v-model="film.is_show" @change="onChangeSwitch( film.id, 'is_show', $event )"> ھالىتى </b-checkbox>
                </b-dropdown-text>
                <b-dropdown-text >
                  <b-checkbox switch v-model="film.is_hidden" @change="onChangeSwitch( film.id, 'is_hidden', $event )"> يۇشۇرامسىز؟ </b-checkbox>
                </b-dropdown-text>
                <b-dropdown-text >
                  <b-checkbox switch v-model="film.is_recommend" @change="onChangeSwitch( film.id, 'is_recommend', $event )"> تەۋسىيەلەمسىز؟ </b-checkbox>
                </b-dropdown-text>
                <b-dropdown-text >
                  <b-checkbox switch v-model="film.in_home" @change="onChangeSwitch( film.id, 'in_home', $event )"> باشبەتتە كۆرۈنەمدۇ؟ </b-checkbox>
                </b-dropdown-text>
                <template v-if="film.is_series">
                  <b-dropdown-divider />
                  <b-dropdown-item @click="onClickEpisodeManage(film.id)">
                    <i class="fa fa-sitemap"></i> قىسىم باشقۇرۇش
                  </b-dropdown-item>
                  <b-dropdown-item @click="onClickCreateEpisode(film.id)">
                    <i class="fa fa-plus-circle"></i> قىسىم قوشۇش
                  </b-dropdown-item>
                </template>
                <b-dropdown-divider ></b-dropdown-divider>
                <b-dropdown-text text-class="text-justify">
                  ئەپچاق ئۇلانمىسىنى كۆچۈرۈش
                </b-dropdown-text>
                <b-dropdown-item @click="onClickCopyPath(film.encoded_id, 'show')">
                  <i class="fa fa-copy"></i> تەپسىلات بەت ئۇلانمىسى
                </b-dropdown-item>
                <b-dropdown-item @click="onClickCopyPath(film.encoded_id, 'home')">
                  <i class="fa fa-copy"></i> باشبەتتىن ئاتلاش ئۇلانمىسى
                </b-dropdown-item>
                <b-dropdown-divider />
                <b-dropdown-item @click="onClickPushUp(film.id)">
                  <i class="fa fa-sort-amount-up"></i>ئىتتىرىش
                </b-dropdown-item>
                <b-dropdown-item :to="{name: 'film.show', params: {id: film.id}}">
                  <i class="fa fa-chart-pie"></i> تەپسىلاتى
                </b-dropdown-item>
                <b-dropdown-item :to="{name: 'film.edit', params: {id: film.id}}">
                  <i class="fa fa-info-circle"></i> تەھرىرلەش
                </b-dropdown-item>
                <b-dropdown-item @click="onClickDelete(film.id)">
                  <i class="fa fa-trash"></i> ئۆچۈرۈش
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template slot="body">
              <div class="kt-widget-item-container">
                <div class="kt-widget kt-widget--user-profile-2">
                  <div class="kt-widget__head">
                    <div class="kt-widget__media">
                      <b-avatar rounded size="50px" text="!" :src="film.preview" />
                    </div>
                    <div class="kt-widget__info">
                      <a class="kt-widget__username">
                        {{ film.title }}
                      </a>
                    </div>
                  </div>
                  <div class="kt-widget__body">
                    <div class="kt-widget__item">
                      <div class="kt-widget__contact">
                        <span class="kt-widget__label">دوۋبەن نۇمۇرى: </span>
                        <span class="kt-widget__data">
                          <b-form-rating variant="primary" precision="2" inline no-border v-model="film.score / 2" readonly /> {{film.score}}
                        </span>
                      </div>
                      <div class="kt-widget__contact">
                        <span class="kt-widget__label">تۈرى: </span>
                        <span class="kt-widget__data">{{ film.categories.length ? film.categories.map(item=> item.title).join(' / ') : 'تۈر تاللىماپسىز' }}</span>
                      </div>
                      <div class="kt-widget__contact">
                        <span class="kt-widget__label">تەۋە رايۇن: </span>
                        <span class="kt-widget__data">{{ film.region ? film.region.title: 'تاللىماپسىز' }}</span>
                      </div>
                      <div class="kt-widget__contact">
                        <span class="kt-widget__label"> كۆرىلىشى: </span>
                        <span class="kt-widget__data">{{ film.click_times ? `${film.click_times} قېتىم` : 'تېخى كۆرۈلمەپتۇ' }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </portlet>
        </b-col>
      </b-row>
      <no-result :visible="!films.length" background="warning" title="ئۇچۇر تېپىلمىدى" desc="مۇناسىۋەتلىك ئ‍ۇچۇر تېپىلمىدى، يېڭىدىن فىلىم قوشۇڭ." >
        <template slot="action">
          <b-button :to="{name: 'film.store'}" class="btn-custom btn-bold btn-upper btn-font-sm  btn-warning">فىلىم قوشۇش</b-button>
        </template>
      </no-result>
    </b-overlay>
  </div>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import NoResult from "@v@/partials/content/NoResult";
  import filmMixin from "@m@/film.mixin";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import pagination from "@v@/components/paginate";
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import commonMethodMixin from "@m@/common/common.method.mixin";
  import msgBoxConfirmMixin from "@m@/common/msgBoxConfirmMixin";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  import {SET_FILM_ERROR, SET_FILM_PAGE} from "@/store/modules/film.module";
  import {SET_FILM_EPISODE_PAGE} from "@/store/modules/film.episode.module";

  export default {
    name: "index",
    components: { Portlet, pagination, NoResult },
    mixins: [filmMixin, formBusyMixin, commonMethodMixin, msgBoxConfirmMixin, sweetAlertMixin],
    data(){
      return {
        filmBusy: false,
        page: 1,
        title: '',
        film_ids: [],
        allSelected: false,
      };
    },
    computed: {},
    created() {
      this.autoRefresh();
    },
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: 'فىلىم باشقۇرۇش', route: {name: 'film.home'}});
      BreadCrumbs.addBreadCrumb({title: 'بارلىق فىلىملەر'});
    },
    watch: {
      film_ids(val, oldVal){
        if ( val.length === 0 ){
          this.allSelected = false;
        }else {
          this.allSelected = val.length === this.films.length;
        }
      }
    },
    methods: {
      autoRefresh(rootPage = null, rootTitle = null){
        if ( rootPage ){
          this.page = rootPage;
        }
        if (rootTitle){
          this.title = rootTitle;
        }
        let { filmPage, title } = this;
        this.getFilms(rootPage ? rootPage : filmPage, rootTitle ? rootTitle : title);
      },
      onChangePage(e){
        let { title, page } = e;
        this.autoRefresh(page, title);
      },
      onRefresh(title){
        this.autoRefresh(1, title);
      },
      onChangeSwitch(id, column, value){
        this.switchColumn({
          resource: 'films',
          id, column, mutationType: SET_FILM_ERROR,
          begin: ()=>{ this.showBusy('filmBusy') },
          after: ()=>{ this.hideBusy('filmBusy') },
        });
      },
      onClickDelete(id){
        this.msgBoxConfirm({
          message: 'مەزكۇر فىلىمنى ئۆچۈرسىڭىز ئەسلىگە قايتۇرالمايسىز، يەنىلا ئۆچۈرۈشنى جەزىملەشتۈرەمسىز ؟',
          confirmCallback: confirm=>{
            if ( confirm ){
              this.deleteModel({
                id, mutationType: SET_FILM_ERROR, resource: 'films',
                begin: ()=>{ this.showBusy('filmBusy')},
                after: ()=>{
                  this.autoRefresh();
                  this.hideBusy('filmBusy');
                },
              })
            }
          }
        });
      },
      toggleAllSelected(){
        this.allSelected = ! this.allSelected
      },
      onClickToggleSelect(){
        this.toggleAllSelected();
        let { allSelected } = this;
        if ( allSelected ){
          this.film_ids = this.films.map(item=> item.id);
        }else {
          this.film_ids = [];
        }
      },
      onClickCopyPath(encodedId, type = 'show'){
        let path = '';
        let message = '';
        switch (type) {
          case "show":
            path = `/pages/show/show?id=${encodedId}&trust=1&index=0`;
            message = 'بىۋاستە تەپسىلات بېتىگە ئاتلاش ئۇلانمىسىنى كۆچۈردىڭىز';
            break;
          case 'home':
            path = `/pages/index/index?target=film&target_id=${encodedId}&target_title=&target_options=0&invitor=&trust=1`;
            message = 'باشبەتتىن تەپسىلات بېتىگە ئاتلاش ئۇلانمىسىنى كۆچۈردىڭىز، دېققەت، 2021-يىلى 12-ئاينىڭ 17- كۈنىدىن كىيىن كودى يوللانغان ئەپچاقلاردىلا نورمال خىزمەت قىلىدۇ.';
            break;
        }
        this.$clipboard(path);
        this.success({ message });
      },
      onClickPushUp(id){
        this.pushUpFilm(id)
          .then(()=>{
            this.success({ message: 'ئىتتىرىش مۇۋاپىقىيەتلىك بولدى!' });
            this.autoRefresh();
          })
      },
      onClickCancelPushUp(id){
        this.cancelPushUpFilm(id)
          .then(()=>{
            this.success({ message: 'ئىتتىرىشنى بىكار قىلىش مۇۋاپىقىيەتلىك بولدى!' });
            this.autoRefresh();
          })
      },
      onClickEpisodeManage(film_id){
        this.$store.commit(SET_FILM_EPISODE_PAGE, {film_id, page: 1});
        this.$router.push({name: 'episode.home', params: {film_id}});
      },
      onClickCreateEpisode(film_id){
        this.$store.commit(SET_FILM_EPISODE_PAGE, {film_id, page: 1});
        this.$router.push({name: 'episode.store', params: {film_id}});
      },
    }
  }
</script>

<style scoped>
  .btn i {
    padding-left: 0;
  }

  .kt-widget__media .b-avatar {
    width: 100% !important;
    max-width: 35px;
  }
</style>
